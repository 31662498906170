// extracted by mini-css-extract-plugin
export var MsoFootnoteReference = "content-module--MsoFootnoteReference--41dba";
export var MsoFootnoteText = "content-module--MsoFootnoteText--8d99d";
export var article = "content-module--article--61952";
export var articleBody = "content-module--articleBody--674cf";
export var articleCollection = "content-module--articleCollection--17a3d";
export var articleContent = "content-module--articleContent--bc61c";
export var articleExcerpt = "content-module--articleExcerpt--f3a27";
export var articleFeature = "content-module--articleFeature--775ef";
export var articlePictorial = "content-module--articlePictorial--7fe05";
export var articleTagWrapper = "content-module--articleTagWrapper--68588";
export var articleWrapper = "content-module--articleWrapper--0d118";
export var categoriesArticle = "content-module--categoriesArticle--b9232";
export var categoryOverview = "content-module--categoryOverview--6a7e1";
export var collectionTitle = "content-module--collectionTitle--bba31";
export var collectionWrapper = "content-module--collectionWrapper--db9df";
export var collectioneTitle = "content-module--collectioneTitle--f98be";
export var collections = "content-module--collections--fe360";
export var collectionsArticle = "content-module--collectionsArticle--05ffe";
export var contentPage = "content-module--contentPage--e6d45";
export var gallery = "content-module--gallery--30444";
export var galleryItem = "content-module--gallery-item--649c4";
export var inlineGatsbyImageWrapper = "content-module--inline-gatsby-image-wrapper--f5535";
export var languagesWrapper = "content-module--languagesWrapper--895bd";
export var lightboxImage = "content-module--lightbox-image--ae0fb";
export var lightboxImageWrapper = "content-module--lightboxImageWrapper--5de27";
export var metaNav = "content-module--metaNav--25ae1";
export var pageContent = "content-module--pageContent--11bf3";
export var pswp__content = "content-module--pswp__content--cad94";
export var pswp__defaultCaption = "content-module--pswp__default-caption--6cd28";
export var recommendedArticle = "content-module--recommendedArticle--70490";
export var relatedArticle = "content-module--relatedArticle--f0eb5";
export var relatedPostsWrapper = "content-module--relatedPostsWrapper--1266e";
export var results = "content-module--results--5fb94";
export var sectionTitle = "content-module--sectionTitle--91278";
export var swiperSlide = "content-module--swiper-slide--0da35";
export var swiperSlideActive = "content-module--swiper-slide-active--d1b82";
export var tagsSidebar = "content-module--tagsSidebar--ffdd8";
export var thumbnail = "content-module--thumbnail--91f4f";
export var topBrackets = "content-module--topBrackets--dda90";
export var wpCaption = "content-module--wp-caption--56f3f";