// extracted by mini-css-extract-plugin
export var MsoFootnoteReference = "article-module--MsoFootnoteReference--9d81e";
export var MsoFootnoteText = "article-module--MsoFootnoteText--01204";
export var article = "article-module--article--ba0fe";
export var articleBody = "article-module--articleBody--4ef0d";
export var articleExcerpt = "article-module--articleExcerpt--70a81";
export var articleFeature = "article-module--articleFeature--9a73c";
export var articlePictorial = "article-module--articlePictorial--445b7";
export var articleTagWrapper = "article-module--articleTagWrapper--89683";
export var categoriesArticle = "article-module--categoriesArticle--bba5e";
export var collectionsArticle = "article-module--collectionsArticle--8f1d0";
export var inlineGatsbyImageWrapper = "article-module--inline-gatsby-image-wrapper--341f2";
export var languagesWrapper = "article-module--languagesWrapper--172e1";
export var recommendedArticle = "article-module--recommendedArticle--219ab";
export var relatedArticle = "article-module--relatedArticle--7f6d8";
export var swiperSlide = "article-module--swiper-slide--20f49";
export var swiperSlideActive = "article-module--swiper-slide-active--58626";
export var thumbnail = "article-module--thumbnail--3bb90";
export var wpCaption = "article-module--wp-caption--e650d";