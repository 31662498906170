// extracted by mini-css-extract-plugin
export var MsoFootnoteReference = "layout-module--MsoFootnoteReference--f8f57";
export var MsoFootnoteText = "layout-module--MsoFootnoteText--41b9a";
export var active = "layout-module--active--a38e1";
export var articleBody = "layout-module--articleBody--1502c";
export var articleCollection = "layout-module--articleCollection--3db5c";
export var articleExcerpt = "layout-module--articleExcerpt--56055";
export var articleFeature = "layout-module--articleFeature--db09f";
export var articlePictorial = "layout-module--articlePictorial--06d0c";
export var articleTagWrapper = "layout-module--articleTagWrapper--6b153";
export var brand = "layout-module--brand--7e967";
export var burgerButton = "layout-module--burgerButton--c6ce1";
export var categories = "layout-module--categories--7730e";
export var categoriesSectionHeader = "layout-module--categoriesSectionHeader--14b74";
export var firstNav = "layout-module--firstNav--8cc9a";
export var inlineGatsbyImageWrapper = "layout-module--inline-gatsby-image-wrapper--f77c0";
export var languagesWrapper = "layout-module--languagesWrapper--e2702";
export var layout = "layout-module--layout--e08e0";
export var menuBottomRowBg = "layout-module--menuBottomRowBg--febe7";
export var menuTopRowBg = "layout-module--menuTopRowBg--db58c";
export var menuTransition = "layout-module--menuTransition--bae7b";
export var relatedArticle = "layout-module--relatedArticle--5bab7";
export var relatedPostsWrapper = "layout-module--relatedPostsWrapper--13691";
export var searchBox = "layout-module--searchBox--c670d";
export var searchResultsWrapper = "layout-module--searchResultsWrapper--5d662";
export var searchWords = "layout-module--searchWords--5d870";
export var secondNav = "layout-module--secondNav--6474d";
export var secondaryNav = "layout-module--secondaryNav--19eff";
export var thumbnail = "layout-module--thumbnail--d0f58";
export var wpCaption = "layout-module--wp-caption--0f770";